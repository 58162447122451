<template>
  <v-app-bar
    dense
    app
    elevate-on-scroll
    color="primary"
    dark
    :style="{ 'z-index': 999 * 9999 }"
  >
    <v-card
      color="transparent"
      elevation="0"
      name="home_btn_from_image"
      exact
      :to="{ path: '' }"
    >
      <v-img
        src="@/assets/logo-header.png"
        max-height="40"
        :width="$vuetify.breakpoint.xs ? '280' : '100%'"
        contain
      ></v-img>
    </v-card>
    <v-spacer />
    <dis-assoc-drop
      background-color="white"
      color="secondary"
      v-if="!$vuetify.breakpoint.mobile && isMgmt"
    ></dis-assoc-drop>
    <template v-slot:extension>
      <v-tabs show-arrows center-active align-with-title grow>
        <template v-for="(route, index) in lauderGetRoutes()">
          <v-tab replace :to="{ name: route.name }" :key="index">
            <v-icon class="mr-2"> fas fa-{{ route.icon }}</v-icon>
            {{ route.label }}
          </v-tab>
        </template>
      </v-tabs>
    </template>
    <v-spacer />
    <v-app-bar-nav-icon
      name="app_side_bar"
      @click="$emit('drawed')"
    ></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "app_bar",
  components: {
    disAssocDrop: () => import("@/components/disAssocDrop")
  },
  data() {
    return {
      btnLoading: false,
      colorDialogue: false,
      color: ""
    };
  },
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    async refreshData() {
      this.btnLoading = true;
      // await this.$store.dispatch("lauderfetchPaymentData");
      this.btnLoading = false;
    }
  },
  computed: {
    ...mapGetters({ isMgmt: "isMgmt" })
  }
};
</script>

<style scoped></style>
